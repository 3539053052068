<template>
  <div class="mainpage">
    <div class="head">
      <div class="head-title">实验作业</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <!-- <div class="line"></div> -->
    <div class="maincontain">
      <div class="searchcontain">
        <div class="left">&nbsp;</div>
        <div class="right">
          <el-radio-group
            v-model="queryParams.test_period_user_status"
            size="medium"
            @change="search"
          >
            <el-radio-button label="1">提交</el-radio-button>
            <el-radio-button label="0">未提交</el-radio-button>
          </el-radio-group>
        </div>
      </div>

      <div class="main-contain">
        <div class="contain">
          <el-table
            ref="singleTable"
            :data="tableData"
            highlight-current-row
            style="width: 100%"
            class="Table"
            :header-cell-style="{
              'font-size': '15px',
              color: '#666666',
              'font-weight': 'bold',
              background: '#FFFFFF',
            }"
            :row-style="{
              'font-size': '15px',
              color: '#222222',
              'font-weight': '400',
            }"
          >
            <el-table-column
              prop="name"
              label="名称"
              min-width="200"
            ></el-table-column>
            <el-table-column
              prop="total_stem_count"
              label="数量"
              min-width="40"
            ></el-table-column>
            <el-table-column
              prop="total_score"
              label="总分"
              min-width="40"
            ></el-table-column>
            <el-table-column
              prop="user_score"
              label="得分"
              min-width="40"
            ></el-table-column>
            <!-- <el-table-column prop="state" label="关联章节"> </el-table-column> -->
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <div class="item-right" v-if="scope.row.review_status !== 2">
                  <el-button
                    type="primary"
                    class="retutnbt"
                    @click="handleAnswer(scope.row)"
                    >答题</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="elpagination">
            <div class="elpagination-title">共{{ total }}条</div>
            <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="queryParams.per_page"
              :current-page="queryParams.page"
              @current-change="pageChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// 获取接口
import { gettestperioduserList } from "@/api/testperioduser";
export default {
  name: "Homemainpage",
  components: {},
  data() {
    return {
      queryParams: {
        related_id: "",
        test_period_user_status: 0,
        per_page: 10,
        page: 1,
      },
      tableData: [],
      total: 0,
    };
  },
  computed: {},
  created() {
    this.queryParams.related_id = this.$route.query.related_id;
    this.init();
  },
  methods: {
    init() {
      gettestperioduserList(this.queryParams).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    search() {
      this.init();
    },
    toback() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        params: {
          id: 6,
          componentId: "Myinternship",
        },
      });
    },
    handleAnswer(row) {
      this.$router.push({
        path: "/home/personalcenter/homeworkanswer",
        query: {
          related_id: this.$route.query.related_id,
          test_period_id: row.test_period_id,
        },
      });
    },
    pageChange(val) {
      //console.log(val);
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .mainpage {
    .head {
      margin-top: 66px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      height: 60px;
      padding-left: 30px;
      border-bottom: 2px solid #cccccc;

      .head-title {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgb(61, 132, 255);
        user-select: none;
        white-space: nowrap;
      }

      .retutnbt {
        margin-right: 10px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: rgb(61, 132, 255);
        border-radius: 4px;
        color: white;
        user-select: none;
        cursor: pointer;
        font-size: 14px;
      }
    }

    .line {
      margin-top: 14px;
      margin-bottom: 4px;
      border-bottom: 2px solid #cccccc;
    }

    .maincontain {
      height: 1008px;
      background: #fcfcfc;
      border-radius: 4px;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;

      .searchcontain {
        height: 56px;
        background: #f7f7f7;
        border: 1px solid #ececec;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;

        .left {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .contain {
            margin-right: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .maintext {
              margin-right: 10px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #888888;
            }

            .elinput {
              width: 200px;

              ::v-deep .el-input__suffix {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        .right {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .bt {
            width: 64px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: #ffffff;
            border: 1px solid rgb(61, 132, 255);
            border-radius: 2px;
            user-select: none;
            cursor: pointer;
          }

          .restbt {
            margin-right: 10px;
            background: #0773fc;
            color: white;
          }
        }
      }

      .main-contain {
        margin-top: 40px;
        margin-left: 10px;
        margin-right: 10px;
        height: 200px;
        width: 100%;
        position: relative;

        .contain {
          position: absolute;
          width: 100%;
          height: 100%;

          .Table {
            width: 100%;

            .item-left {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .title {
                margin-left: 10px;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
              }
            }

            .item-middle {
              .title {
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
              }
            }

            .item-right {
              display: flex;
              justify-content: center;
              align-items: center;

              .bt {
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #0773fc;
                user-select: none;
                cursor: pointer;
              }

              .line2 {
                margin-right: 8px;
                margin-left: 8px;
                height: 14px;
                border-right: 2px solid #cccccc;
              }
            }
          }

          .elpagination {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            .elpagination-title {
              margin-right: 4px;
            }
          }
        }
      }
    }

    ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background-color: rgb(61, 132, 255);
      border-color: rgb(61, 132, 255);
      box-shadow: -1px 0 0 0 rgb(61, 132, 255);
    }

    ::v-deep .el-button--primary {
      background-color: rgb(61, 132, 255);
      // color: rgb(61, 132, 255);
    }
  }
}
@media screen and (min-width: 1200px) {
  .mainpage {
    .head {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      height: 60px;
      padding-left: 30px;
      border-bottom: 2px solid #cccccc;

      .head-title {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgb(61, 132, 255);
        user-select: none;
        white-space: nowrap;
      }

      .retutnbt {
        margin-right: 40px;
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: rgb(61, 132, 255);
        border-radius: 4px;
        color: white;
        user-select: none;
        cursor: pointer;
        font-size: 14px;
      }
    }

    .line {
      margin-top: 14px;
      margin-bottom: 4px;
      border-bottom: 2px solid #cccccc;
    }

    .maincontain {
      height: 1008px;
      background: #fcfcfc;
      border-radius: 4px;
      padding-top: 20px;
      padding-left: 20px;
      padding-right: 20px;

      .searchcontain {
        height: 56px;
        background: #f7f7f7;
        border: 1px solid #ececec;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-right: 20px;

        .left {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .contain {
            margin-right: 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .maintext {
              margin-right: 10px;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #888888;
            }

            .elinput {
              width: 200px;

              ::v-deep .el-input__suffix {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
        }

        .right {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .bt {
            width: 64px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            background: #ffffff;
            border: 1px solid rgb(61, 132, 255);
            border-radius: 2px;
            user-select: none;
            cursor: pointer;
          }

          .restbt {
            margin-right: 10px;
            background: #0773fc;
            color: white;
          }
        }
      }

      .main-contain {
        margin-top: 40px;
        margin-left: 10px;
        margin-right: 10px;
        height: 200px;
        width: 100%;
        position: relative;

        .contain {
          position: absolute;
          width: 100%;
          height: 100%;

          .Table {
            width: 100%;

            .item-left {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .title {
                margin-left: 10px;
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
              }
            }

            .item-middle {
              .title {
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #222222;
              }
            }

            .item-right {
              display: flex;
              justify-content: center;
              align-items: center;

              .bt {
                font-size: 15px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #0773fc;
                user-select: none;
                cursor: pointer;
              }

              .line2 {
                margin-right: 8px;
                margin-left: 8px;
                height: 14px;
                border-right: 2px solid #cccccc;
              }
            }
          }

          .elpagination {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            align-items: center;

            .elpagination-title {
              margin-right: 4px;
            }
          }
        }
      }
    }

    ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      background-color: rgb(61, 132, 255);
      border-color: rgb(61, 132, 255);
      box-shadow: -1px 0 0 0 rgb(61, 132, 255);
    }

    ::v-deep .el-button--primary {
      background-color: rgb(61, 132, 255);
      // color: rgb(61, 132, 255);
    }
  }
}
</style>
